<template>
  <div class="content">
    <div class="head">
      <el-row>
        <el-col :span="9">
          <div style="margin-top: 8px;text-align:right">
            <img src="@/assets/dataview/title-left.png" />
          </div>
        </el-col>
        <el-col :span="6">
          <h2 style="padding-top: 16px;text-align: center;margin: 0;">项目监控大屏显示</h2>
        </el-col>
        <el-col :span="9">
          <div style="margin-top: 8px;">
            <img src="@/assets/dataview/title-right.png" />
          </div>
        </el-col>
      </el-row>

      <div class="time">{{datetime|datetime('YYYY-MM-DD HH:mm:ss')}}</div>
    </div>
    <el-row>
      <el-col :span="6">
        <div class="data-box">
          <i class="topL"></i>
          <i class="topR"></i>
          <i class="bottomL"></i>
          <i class="bottomR"></i>
          <div id="testPie" style="height:300px"></div>
        </div>
      </el-col>
      <el-col :span="12">
        <div id="map">
          <div id="mapContainer"></div>
        </div>
      </el-col>
      <el-col :span="6"></el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  mounted() {
    this.interval = setInterval(() => {
      this.datetime = new Date();
    }, 1000);
    this.$nextTick(() => {
      this.initMap();
      this.initCharts();
    });
  },
  destroyed() {
    clearInterval(this.interval);
  },
  data() {
    return {
      interval: null,
      datetime: new Date(),

      map: null
    };
  },
  methods: {
    initMap() {
      this.map = new AMap.Map("mapContainer", {
        mapStyle: "amap://styles/cd3487baaf7ab1612b5cfe6bbb89018d"
      });
    },
    initCharts() {
      this.$echarts.init(document.getElementById("testPie"), "dark").setOption({
        backgroundColor: "transparent",
        title: {
          left: "center",
          text: "物资"
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} : ￥{c}元 ({d}%)"
        },
        series: [
          {
            type: "pie",
            radius: [0, "60%"],
            data: [
              { value: 335, name: "直接访问" },
              { value: 310, name: "邮件营销" },
              { value: 234, name: "联盟广告" },
              { value: 135, name: "视频广告" },
              { value: 1548, name: "搜索引擎" }
            ]
          }
        ]
      });
    }
  }
};
</script>

<style scoped>
.content {
  height: 100vh;
  width: 100vw;
  background-color: rgb(0,12,59);
  color: #fff;
}

.head {
  height: 60px;
}
.time {
  position: absolute;
  left: 16px;
  top: 20px;
}

.data-box {
  border: 2px solid #032d60;
  -webkit-box-shadow: #07417a 0 0 10px;
  -moz-box-shadow: #07417a 0 0 10px;
  box-shadow: inset 0 0 30px #07417a;
  position: relative;
} /*四个角*/
.topL {
  width: 20px;
  height: 20px;
  border-top-width: 2px;
  border-top-color: #26c6f0;
  border-top-style: solid;
  border-left-width: 2px;
  border-left-color: #26c6f0;
  border-left-style: solid;
  position: absolute;
  top: -2px;
  left: -2px;
}

.topR {
  width: 20px;
  height: 20px;
  border-top-width: 2px;
  border-top-color: #26c6f0;
  border-top-style: solid;
  border-right-width: 2px;
  border-right-color: #26c6f0;
  border-right-style: solid;
  position: absolute;
  top: -2px;
  right: -2px;
}

.bottomL {
  width: 20px;
  height: 20px;
  border-bottom-width: 2px;
  border-bottom-color: #26c6f0;
  border-bottom-style: solid;
  border-left-width: 2px;
  border-left-color: #26c6f0;
  border-left-style: solid;
  position: absolute;
  bottom: -2px;
  left: -2px;
}

.bottomR {
  width: 20px;
  height: 20px;
  border-bottom-width: 2px;
  border-bottom-color: #26c6f0;
  border-bottom-style: solid;
  border-right-width: 2px;
  border-right-color: #26c6f0;
  border-right-style: solid;
  position: absolute;
  bottom: -2px;
  right: -2px;
}

#mapContainer {
  height: calc(100vh - 60px);
}
#mapContainer >>> .amap-logo img {
  display: none;
}
#mapContainer >>> .amap-copyright {
  color: transparent;
}
</style>